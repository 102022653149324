<template>
  <app-form-autocomplete
    v-model="selected"
    v-model:query="filters.query"
    class="min-w-[200px]"
    :clearable="clearable"
    :hide-details="hideDetails"
    item-title="full_name"
    :item-value="itemKey"
    :items="items"
    :label="label"
    :loading="pending"
    :multiple="multiple"
    :name="name"
    :required="required"
  >
    <template #item="{ props, item }">
      <v-list-item v-bind="props">
        <template #prepend>
          <app-avatar
            class="mr-1"
            :full-name="item.full_name"
            :size="30"
            :src="item.avatar_url"
          />
        </template>
        <v-list-item-title>
          {{ item.full_name }}
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-if="allowCreation" #no-data>
      <v-list-item :title="`No trainer found for &quot;${filters.query}&quot;`">
        <template #append>
          <v-btn
            color="primary"
            size="small"
            variant="tonal"
            @click="onCreateClick"
          >
            Create a new trainer
          </v-btn>
        </template>
      </v-list-item>
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import type { TeacherAutocompleteView } from "~/business-areas/teacher/teacher.model";

const selected = defineModel<
  TeacherAutocompleteView | TeacherAutocompleteView[] | undefined
>("selected", { default: undefined });

const model = defineModel<
  string | number | Array<string | number> | null | undefined
>({
  default: undefined,
});

const properties = withDefaults(
  defineProps<{
    multiple?: boolean;
    required?: boolean;
    hideDetails?: boolean;
    clearable?: boolean;
    allowCreation?: boolean;
    name?: string;
    itemKey?: "id" | "user_id";
    label?: string;
  }>(),
  {
    itemKey: "id",
    name: undefined,
    label: "Teacher",
  },
);

const filters = reactive({ query: undefined });
const { items, pending } = useTeacherList("teacher_autocomplete", filters);

const client = useDatabaseClient();

watch(
  selected,
  (input) => {
    if (!input) {
      model.value = undefined;

      return;
    }

    model.value = Array.isArray(input)
      ? input.map((item) => item[properties.itemKey])
      : input[properties.itemKey];
  },
  { immediate: false },
);

async function updateSelectedTeacherBasedOnModelValue() {
  const modelStringifiedValue = toArray(model.value).join("_");
  const selectedStringifiedValue = toArray(selected.value)
    .filter(Boolean)
    .map((t) => t[properties.itemKey])
    .join("_");

  if (modelStringifiedValue === selectedStringifiedValue) return;

  const { data } = await client
    .from("teacher_with_companies")
    .select()
    .in(
      properties.itemKey,
      toArray(model.value).filter(
        (item) => item !== null && item !== undefined,
      ),
    )
    .returns<TeacherAutocompleteView[]>();

  selected.value = properties.multiple ? (data ?? []) : (data ?? [])[0];
}

watch(model, updateSelectedTeacherBasedOnModelValue, { immediate: true });

function onCreateClick() {
  window.open("/teachers/new");
}
</script>
